import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import {useIntl} from 'react-intl';
import {Button, Fade, Menu, MenuItem} from "@mui/material";

const SelectLanguage = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const intl = useIntl()


	return (
		<div>
			<Button
				id="fade-button"
				aria-controls={open ? 'fade-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				style={{color: "white"}}
			>
				{intl.locale}
			</Button>
			<Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				style={{

				}}
			>
				{props.langs.map(lang =>
					<Link
						to={lang.link}
						key={lang.langKey}
						style={{
							backgroundColor: "black",
							color: 'black',
							textTransform: "uppercase"
						}}
					>
						<MenuItem onClick={handleClose}>
							{lang.langKey}
						</MenuItem>
					</Link>
				)}
			</Menu>
		</div>
	);
};

SelectLanguage.propTypes = {
	langs: PropTypes.array
};

export default SelectLanguage;
