import * as React from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {Button, Container} from "@mui/material";
import  * as styles from './style.module.css';
import {FormattedMessage, injectIntl} from "react-intl";


const Footer = (props) => {
  const local = props.intl.locale;

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.columns}>
          <div className={styles.column}>
            <span>© {new Date().getFullYear()} All rights reserved</span>
          </div>
          <div className={styles.column} style={{display: "flex", flexDirection: "column"}}>
            <a title="linkedin"
               href="https://www.linkedin.com/in/philip-nedelev-9a7264b5/"
               target="_blank"
               rel="noreferrer">
              LinkedIn
            </a>
            <a title="youtube"
               href="https://www.youtube.com/channel/UCAlG1W0Qlb3sSxjrMYQxnpQ"
               target="_blank"
               rel="noreferrer">
              YouTube
            </a>
            <a title="medium"
               href="https://medium.com/@philipnedelev"
               target="_blank"
               rel="noreferrer">
              Medium
            </a>
          </div>
          <div>
            <a title="author" href={`${local !== "uk" ? ("/" + local) : ""}/author`}>
              <FormattedMessage id="author" />
            </a>
          </div>
          <div className={styles.column} style={{display: "flex", justifyContent: "end"}}>
            <Button
              onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"})
              }}
              variant="text"
              startIcon={<ArrowUpwardIcon/>}
            >
              <FormattedMessage id="up" />
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  );
};



export default injectIntl(Footer);
