import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import {StaticQuery, withPrefix, graphql} from "gatsby";
import "../../../node_modules/normalize.css/normalize.css"
import  * as styles from './style.module.css';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import {IntlProvider} from "react-intl";
import '../../styles/global.css';
import {useContext, useEffect, useState} from "react";
import {darkThemeColors} from "../../constants/colors";


const defaultTheme = "light";
export const ThemeContext = React.createContext(defaultTheme);

const Layout = (props) => {
	const { children, location, title, description, i18nMessages } = props;
	const [theme, setTheme] = useState(defaultTheme);

	useEffect(() => {
		if(localStorage.getItem('theme')){
			setTheme(localStorage.getItem('theme'));
		} else {
			localStorage.setItem('theme', defaultTheme)
			setTheme(defaultTheme);
		}
	}, [])

	const changeTheme = () => {
		const themeVal = (theme === 'light') ? 'dark' : 'light';
		localStorage.setItem('theme', themeVal);
		setTheme(themeVal);
	}

	return (
		<StaticQuery
			query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
        }
      `}
			render={data => {
				const url = location.pathname;
				// const url = navigator.language;
				const { langs, defaultLangKey } = data.site.siteMetadata.languages;
				const langKey = getCurrentLangKey(langs, defaultLangKey, url);
				const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
				const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
					.map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));

				// console.log(langKey)
				// console.log(defaultLangKey)
				// console.log(i18nMessages)

				return (
					<ThemeContext.Provider value={theme}>
						<IntlProvider
							locale={langKey}
							defaultLocale={defaultLangKey}
							messages={i18nMessages}
						>
							<div style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-between",backgroundColor:(theme === "light") ? "white" : darkThemeColors.background}}>
								<Helmet>
									<html lang={langKey} />
									<title>{title}</title>
									<meta name="description" content={description} />
									<meta name="viewport" content="width=device-width, initial-scale=1.0" />
									<meta charSet="UTF-8" />

									<link
										rel="apple-touch-icon"
										sizes="180x180"
										href={`${withPrefix("/")}img/favicon/apple-touch-icon.png`}
									/>
									<link
										rel="icon"
										type="image/png"
										href={`${withPrefix("/")}img/favicon/favicon-32x32.png`}
										sizes="32x32"
									/>
									<link
										rel="icon"
										type="image/png"
										href={`${withPrefix("/")}img/favicon/favicon-16x16.png`}
										sizes="16x16"
									/>
								</Helmet>
								<Navbar langs={langsMenu} theme={theme} changeTheme={changeTheme}/>
								<div
									style={{
										backgroundColor:(theme === "light") ? "white" : darkThemeColors.background,
										color:(theme === "light") ? "black" : darkThemeColors.text,
									}}
								>
									{children}
								</div>
								<Footer/>
							</div>
						</IntlProvider>
					</ThemeContext.Provider>
				)
			}}
		/>
	);
};

export default Layout;
